
<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.itemsAndCommoditiesList')}}</b>
    </CCardHeader>
    <CCardBody>
        <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
            <CustomTab :title="$t('label.heading')" >
              <template #title>
                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Heading.png" alt="Card image cap">
                {{$t('label.heading')}}                
              </template>
              <Heading :Tab="Tab"/>   
            </CustomTab>
            <CustomTab :title="$t('label.commodity')">
              <template #title>
                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Commodity.svg" alt="Card image cap">
                {{$t('label.commodity')}}                
              </template>
              <Commodity :Tab="Tab"/>
            </CustomTab>
        </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import Heading from './heading/index';
import Commodity from './commodity/index';

function handleTab(tab) {
  this.Tab = tab;
}
function data() {
  return {
    Tab: 0,
  };
}
export default {
  name: 'index',
  data,
  methods:{
    handleTab,
  },
  components: {
    CustomTabs,
    CustomTab,
    Heading,
    Commodity
  },
};
</script>